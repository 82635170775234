import React, { useCallback, useRef, useMemo, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useRecoilState, useRecoilValue } from 'recoil'
import { stillDialogProjectState, stillDialogOpenState, stillDialogSelectedImageState } from './stillDialogState'
import Dialog from '../Dialog'
import Flickity from 'react-flickity-component'
import ResponsiveImage from '../ResponsiveImage'
import RichText from '../RichText'
import theme from '../../style/theme'
import { isCurrentBreakpointSmall } from '../../state/layout'
import reduce from 'lodash/reduce'
import cn from 'classnames'
import gsap from 'gsap'
import delay from 'lodash/delay'

const flickityOptions = {
  cellAlign: 'center',
  pageDots: false,
  contain: false,
  prevNextButtons: false,
  freeScroll: false
}

const getFullImageDimensions = (image) => {
  const windowHeight = window.innerHeight - (2 * theme.margin)
  const windowWidth = window.innerWidth * 0.9
  if (windowHeight * image.aspect > windowWidth) {
    return {
      x: windowWidth,
      y: windowWidth * (1 / image.aspect)
    }
  } else {
    return {
      x: windowHeight * image.aspect,
      y: windowHeight
    }
  }
}

const ProjectImage = ({ image }) => {
  const imageRef = useRef()
  const classes = useStyles()
  const selectedImage = useRecoilValue(stillDialogSelectedImageState)

  useEffect(() => {
    if (selectedImage === image) {
      const { left, width, height } = imageRef.current.getBoundingClientRect()
      const fullSize = getFullImageDimensions(image)
      const x = (window.innerWidth / 2) - (left + (width / 2))
      gsap.to(imageRef.current, { x, scaleX: fullSize.x / width, scaleY: fullSize.y / height, duration: 0.15, ease: 'sine.out', zIndex: 10000 })
      gsap.to(imageRef.current.parentNode, { zIndex: 10000, duration: 0.15 })
    } else {
      gsap.to(imageRef.current, { x: 0, scaleX: 1, scaleY: 1, duration: 0.15, ease: 'sine.out', zIndex: 1, opacity: 1 })
      gsap.to(imageRef.current.parentNode, { zIndex: 1, duration: 0.15 })
    }
  }, [selectedImage, image])

  return (
    <ResponsiveImage {...image} className={classes.image} ref={imageRef} />
  )
}

export default () => {
  const classes = useStyles()
  const [open, setOpen] = useRecoilState(stillDialogOpenState)
  const project = useRecoilValue(stillDialogProjectState)
  const dialogRef = useRef()
  const contentRef = useRef()
  const overlayRef = useRef()
  const imageBoxRef = useRef()
  const [selectedImage, setSelectedImage] = useRecoilState(stillDialogSelectedImageState)
  const isSmall = useRecoilValue(isCurrentBreakpointSmall)
  const localsRef = useRef({ project })

  useEffect(() => {
    localsRef.current.project = project
  }, [project])

  const onClose = useCallback(() => {
    if (selectedImage) {
      setSelectedImage(null)
    } else {
      window.history.pushState({}, null, '/')
      setOpen(false)
    }
  }, [setOpen, selectedImage, setSelectedImage])

  const setFlickityRef = useCallback((flickity) => {
    if (flickity) {
      const onClick = (event, pointer, cellElement, cellIndex) => {
        if (!isSmall) {
          const offset = !localsRef.current.project.still.text
            ? 0
            : isSmall ? localsRef.current.project.still.text.length : Math.ceil(localsRef.current.project.still.text.length / 2)
          if (!selectedImage && cellIndex >= offset) {
            setSelectedImage(localsRef.current.project.still.images[cellIndex - offset])
          } else {
            setSelectedImage(null)
          }
        }
      }
      flickity.on('staticClick', onClick)
      flickity.on('change', flickity.resize())
      localsRef.current.flickity = flickity
    }
  }, [selectedImage, setSelectedImage, isSmall])

  const textGroups = useMemo(() => {
    if (!project) return []
    if (isSmall) return project.still.text?.map(text => [text])
    return reduce(project.still.text, (result, text, index) => {
      const i = Math.floor(index / 2)
      if (!result[i]) result[i] = []
      result[i].push(text)
      return result
    }, [])
  }, [project, isSmall])

  const onFullImageClick = useCallback(() => {
    setSelectedImage(null)
  }, [setSelectedImage])

  useEffect(() => {
    if (selectedImage) {
      const { x, y } = getFullImageDimensions(selectedImage)
      gsap.set(imageBoxRef.current, { width: x, height: y })
    }
    if (contentRef.current) {
      gsap.to(contentRef.current.querySelectorAll('.richTextCell'), { duration: 0.15, ease: 'sine.out', opacity: 1 })
    }
  }, [selectedImage])

  const onNextClick = useCallback(() => {
    if (localsRef.current.flickity) {
      localsRef.current.flickity.resize()
      localsRef.current.flickity.next()
    }
  }, [])

  const onPreviousClick = useCallback(() => {
    if (localsRef.current.flickity) {
      localsRef.current.flickity.resize()
      localsRef.current.flickity.previous()
    }
  }, [])

  useEffect(() => {
    if (localsRef.current.flickity && open) {
      // Hack, hack hack
      localsRef.current.flickity.resize()
      delay(() => {
        console.log('resize')
        localsRef.current.flickity.resize()
      }, 100)
      delay(() => {
        console.log('resize')
        localsRef.current.flickity.resize()
      }, 200)
      delay(() => {
        console.log('resize')
        localsRef.current.flickity.resize()
      }, 500)
    }
  }, [open])

  const children = useMemo(() => {
    if (!project) return []
    return [
      ...(textGroups?.map((textGroup, i) => (
        <div key={i} className={cn(classes.cell, 'richTextCell')}>
          {textGroup.map((text, j) => (
            <RichText key={project.id + j} content={text.text} className={classes.content} />
          ))}
        </div>
      )) || []),
      ...(project.still.images?.map((image, i) => (
        <div key={project.id + i} className={classes.cell}>
          <ProjectImage image={image} />
        </div>
      )) || [])
    ]
  }, [project, textGroups])

  return (
    open && (
      <Dialog open={open} onClose={onClose} ref={dialogRef}>
        {project && (
          <div className={classes.project} ref={contentRef}>
            <div className={classes.flickityWrapper}>
              <Flickity
                className={classes.flickity}
                options={flickityOptions}
                disableImagesLoaded
                flickityRef={setFlickityRef}
                key={isSmall}
              >
                {children}
              </Flickity>
            </div>
          </div>
        )}
        <button className={classes.nextButton} onClick={onNextClick} aria-label='Next Slide' />
        <button className={classes.previousButton} onClick={onPreviousClick} aria-label='Previous Slide' />
        <div
          className={cn(classes.overlay, { show: selectedImage })}
          ref={overlayRef}
          onClick={onFullImageClick}
          onKeyDown={null}
          role='button'
          aria-label='Close'
          tabIndex={-1}
        />
        {/* <ResponsiveImage {...(selectedImage ?? {})} className={cn(classes.imageBox, { show: selectedImage })} ref={imageBoxRef} /> */}
      </Dialog>
    )
  )
}

const useStyles = createUseStyles({
  project: {
    height: '100%',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '80vh',
      margin: ['10vh', 0]
    }
  },
  flickityWrapper: {
    width: '100%'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    backgroundColor: theme.colors.black,
    opacity: 0,
    '&.show': {
      pointerEvents: 'all'
    }
  },
  flickity: {
    outline: 'none'
  },
  image: {
    margin: [0, theme.margin / 4],
    width: '80vw',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      '& picture:before': {
        paddingTop: 0,
        display: 'none'
      },
      '& img': {
        height: '40vw',
        width: 'auto',
        position: 'static'
      }
    }
  },
  content: {
    width: '80vw',
    margin: 0,
    fontSize: 14,
    marginRight: theme.margin,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.margin,
      width: '45vw'
    },
    [theme.breakpoints.up('lg')]: {
      width: '28vw'
    },
    [theme.breakpoints.up('xxl')]: {
      margin: [0, theme.margin],
      fontSize: theme.vw(14, 'xxl')
    },
    '& h2': {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: 10,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('xxl')]: {
        fontSize: theme.vw(10, 'xxl')
      }
    }
  },
  cell: {
    height: '80vh',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  imageBox: {
    position: 'absolute',
    opacity: 0,
    pointerEvents: 'none',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    '&.show': {
      opacity: 1
    },
    '& picture:before': {
      paddingTop: 0
    }
  },
  nextButton: {
    position: 'absolute',
    top: 100,
    right: 0,
    width: '20%',
    bottom: 0,
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    outline: 'none',
    cursor: 'url("data:image/svg+xml,%3Csvg width=\'24\' height=\'20\' viewBox=\'0 0 24 20\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M11 2.82843L13.8284 0L23.7279 9.8995L20.8995 12.7279L20.8995 12.7279L13.8284 19.799L10.9999 16.9706L16.0711 11.8995H0V7.89947L16.071 7.89947L11 2.82843Z\' fill=\'%23F1F1F1\'/%3E%3C/svg%3E%0A") 12 10, e-resize'
  },
  previousButton: {
    position: 'absolute',
    top: 100,
    left: 0,
    width: '20%',
    bottom: 0,
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    outline: 'none',
    cursor: 'url("data:image/svg+xml,%3Csvg width=\'24\' height=\'20\' viewBox=\'0 0 24 20\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12.7275 2.82843L9.89911 0L-0.000383377 9.8995L2.82804 12.7279L2.82806 12.7279L9.89917 19.799L12.7276 16.9706L7.65648 11.8995H23.7275V7.89947L7.6565 7.89947L12.7275 2.82843Z\' fill=\'%23F1F1F1\'/%3E%3C/svg%3E%0A") 12 10, e-resize'
  }

})

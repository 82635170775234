import useDebouncedCallback from './useDebouncedCallback'
import useWindowResize from './useWindowResize'

const layoutState = { y: 0, init: false }

export default (callback) => {
  useWindowResize(useDebouncedCallback(() => {
    layoutState.windowWidth = window.innerWidth
    layoutState.windowHeight = window.innerHeight
    callback(layoutState)
  }, 200, []), true)
}

import { useEffect, useRef } from 'react'
import Hls from 'hls.js'
import { primaryInput } from 'detect-it'

const useHlsPlayer = (playerRef, source, enabled = true) => {
  const hlsRef = useRef
  useEffect(() => {
    if (enabled) {
      const video = playerRef.current
      if (source) {
        if (video.canPlayType('application/vnd.apple.mpegurl')) {
          video.src = source
        } else if (Hls.isSupported()) {
          hlsRef.current = new Hls({
            capLevelToPlayerSize: true,
            startLevel: primaryInput === 'touch' ? '2' : '4'
          })
          hlsRef.current.loadSource(source)
          hlsRef.current.attachMedia(playerRef.current)
          return () => {
            if (hlsRef.current) {
              hlsRef.current.detachMedia()
              hlsRef.current.destroy()
              hlsRef.current = null
            }
          }
        }
      }
    }
  }, [source, enabled]) /* eslint-disable-line */

  return hlsRef
}

export default useHlsPlayer

import React, { useRef, useEffect, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'
import useBodyScrollLock from '../hooks/useBodyScrollLock'
import CloseButton from './CloseButton'
import gsap from 'gsap'
import composeRefs from '../helpers/composeRefs'

export default forwardRef(({ open, onClose, children }, ref) => {
  const classes = useStyles()
  const dialogRef = useRef()
  const lockRef = useBodyScrollLock(open)

  useEffect(() => {
    gsap.to(dialogRef.current, { opacity: open ? 1 : 0, pointerEvents: open ? 'all' : 'none', duration: 0.55, ease: 'power2.inOut' })
  }, [open])

  return (
    <section className={classes.dialog} ref={composeRefs(dialogRef, ref)}>
      <div className={classes.inner} ref={lockRef}>
        <header className={classes.header}>
          <CloseButton onCloseClick={onClose} />
        </header>
        {children}
      </div>
    </section>
  )
})

const useStyles = createUseStyles({
  dialog: {
    opacity: 0,
    pointerEvents: 'none',
    position: 'fixed',
    top: 0,
    height: '100%',
    left: 0,
    right: 0,
    zIndex: theme.zIndex.dialog,
    backgroundColor: theme.colors.black,
    color: theme.colors.white
  },
  inner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'auto',
    overflowX: 'hidden'
  },
  header: {
    position: 'fixed',
    left: 0,
    right: 0,
    mixBlendMode: 'difference',
    margin: theme.spacing(1),
    textAlign: 'right',
    zIndex: theme.zIndex.header,
    [theme.breakpoints.up('xs')]: {
      margin: [theme.spacing(2.5), theme.spacing(3), theme.spacing(2.5)]
    }
  }
})

import React, { useRef, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import useHlsPlayer from '../hooks/useHlsPlayer'
import find from 'lodash/find'
import findLast from 'lodash/findLast'
import cn from 'classnames'
import get from 'lodash/get'
import composeRefs from '../helpers/composeRefs'
import ResponsiveImage from './ResponsiveImage'

export default forwardRef(({ className, vimeoVideo }, ref) => {
  const classes = useStyles()
  const playerRef = useRef()
  const url = get(find(get(vimeoVideo, ['sizes'], []), source => source.quality === 'hls'), ['url'])
  const mp4Url = get(findLast(get(vimeoVideo, ['sizes'], []), source => source.quality === 'hd'), ['url'])
  const image = get(vimeoVideo, ['image'])

  useHlsPlayer(playerRef, url, url)

  return (
    <div className={classes.container}>
      {image && <ResponsiveImage {...image} className={classes.posterImage} />}
      <video
        ref={composeRefs(ref, playerRef)}
        src={url || mp4Url}
        className={cn(classes.video, className)}
        controls
        playsInline
        poster={image.sizes[4].url}
      >
        <track kind='captions' />
      </video>
    </div>
  )
})

const useStyles = createUseStyles({
  container: {
    position: 'relative'
  },
  video: {
    width: '100%',
    display: 'block',
    position: 'relative',
    outline: 'none'
  },
  posterImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
})

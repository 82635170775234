import * as breakpoints from './breakpoints'
import * as fonts from './fonts'

const colors = {
  black: '#1E1E1E',
  white: '#F1F1F1',
  background: '#F1F1F1'
}

export const vw = (size, breakpoint = 'lg') => {
  return `${(size / breakpoints.values[breakpoint]) * 100}vw`
}

const globalStyles = {
  '@font-face': fonts.faces,
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    '-webkit-tap-highlight-color': 'transparent'
  },
  ':root': {
    '--scrollbar-width': '0px'
  },
  html: {
    lineHeight: 1.5
    // scrollBehavior: 'smooth'
  },
  body: {
    padding: 0,
    margin: 0,
    backgroundColor: colors.background,
    color: colors.black,
    width: '100%',
    '-webkitFontSmoothing': 'antialiased',
    '-mozOsxFontSmoothing': 'grayscale',
    fontFamily: fonts.body,
    fontWeight: fonts.bodyFontWeight,
    fontSize: 16,
    lineHeight: 1.5
  },
  'h1,h2,h3,h4,h5,h6': {
    marginTop: 0,
    marginBottom: '0.4em'
  },
  h2: {
    fontWeight: 'bold',
    fontSize: 34,
    [breakpoints.up('sm')]: {
      fontSize: 60
    },
    [breakpoints.up('lg')]: {
      fontSize: vw(60, 'lg')
    }
  },
  h3: {
    fontWeight: 'bold',
    fontSize: 30
  },
  h5: {
    fontWeight: 'bold',
    fontSize: 14,
    [breakpoints.up('lg')]: {
      fontSize: vw(14, 'lg')
    }
  },
  p: {
    margin: [0, 0, '1.5em']
  },
  '.srOnly': {
    border: '0 !important',
    clip: 'rect(1px, 1px, 1px, 1px) !important',
    clipPath: 'inset(50%) !important',
    height: '1px !important',
    margin: '-1px !important',
    overflow: 'hidden !important',
    padding: '0 !important',
    position: 'absolute !important',
    width: '1px !important',
    whiteSpace: 'nowrap !important'
  },
  a: {
    color: 'currentColor',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  button: {
    fontFamily: fonts.body
  },
  '.clearFix': {
    '&::before,&::after': {
      content: '" "', // 1
      display: 'table' // 2
    },
    '&::after': {
      clear: 'both'
    }
  }
}

export default {
  global: globalStyles,
  breakpoints,
  colors,
  zIndex: {
    noise: 0,
    section: 1,
    header: 10000,
    dialog: 10001
  },
  spacing: (count = 1) => {
    return `${count}rem`
  },
  margin: 20,
  vw
}

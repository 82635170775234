import { oneLineTrim } from 'common-tags'

export const unquotedBodyFace = 'ABCFavorit'
export const bodyFace = `"${unquotedBodyFace}"`
export const body = `${bodyFace}, "Lucida Sans", Tahoma, sans-serif`
export const bodyFontWeight = 350

export const faces = [{
  fontFamily: bodyFace,
  src: oneLineTrim`
    url('/ABCFavorit-Light.woff2') format('woff2'),
    url('/ABCFavorit-Light.woff') format('woff')`,
  fontWeight: 'normal',
  fontStyle: 'normal'
}, {
  fontFamily: bodyFace,
  src: oneLineTrim`
    url('/ABCFavorit-Bold.woff2') format('woff2'),
    url('/ABCFavorit-Bold.woff') format('woff')`,
  fontWeight: 'bold',
  fontStyle: 'normal'
}]

import { atom } from 'recoil'

export const selectedMovingState = atom({
  key: 'selectedMoving',
  default: null
})

export const selectedStillState = atom({
  key: 'selectedStill',
  default: null
})

export const selectedTagState = atom({
  key: 'selectedTag',
  default: 'moving'
})

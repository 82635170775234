import React, { useCallback, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import AnimatedLink from '../AnimatedLink'
import Hamburger from './Hamburger'
import Logo from './Logo'
import { useRecoilState, useRecoilValue } from 'recoil'
import { headerOpenState } from './headerState'
import gsap from 'gsap'
import toArray from 'lodash/toArray'
import reverse from 'lodash/reverse'
import { isCurrentBreakpointMobile, isSiteLoaded } from '../../state/layout'
import useLayoutStateCallback from '../../hooks/useLayoutStateCallback'
import { scrollToElement, useScrollListener } from '../useSmoothScrollbar'
import { selectedTagState } from '../Slices/FeaturedProjects/featuredProjectsState'

export default ({ children, props }) => {
  const classes = useStyles()
  const open = useRecoilValue(headerOpenState)
  const isMobile = useRecoilValue(isCurrentBreakpointMobile)
  const menuRef = useRef()
  const logoRef = useRef()
  const headerRef = useRef()
  const loaded = useRecoilValue(isSiteLoaded)
  const [, setSelectedTab] = useRecoilState(selectedTagState)

  const localsRef = useRef({ showLogo: false })
  localsRef.current.isMobile = isMobile

  useLayoutStateCallback(useCallback((layoutState) => {
    localsRef.current.windowHeight = layoutState.windowHeight
  }, []))

  useScrollListener(useCallback((scroll) => {
    const { showLogo } = localsRef.current
    if (localsRef.current.isMobile) {
      localsRef.current.showLogo = true
    } else {
      const offset = localsRef.current.windowHeight
      if (scroll.y >= offset && !showLogo) {
        localsRef.current.showLogo = true
        gsap.to(logoRef.current.children[0], { y: '0%', duration: 0.25, ease: 'expo.out' })
      }
      if (scroll.y < offset && showLogo) {
        localsRef.current.showLogo = false
        gsap.to(logoRef.current.children[0], { y: '100%', duration: 0.25, ease: 'expo.out' })
      }
    }
  }, []))

  useEffect(() => {
    if (logoRef.current && loaded) {
      const links = menuRef.current.children[0].children
      const timeline = gsap.timeline()
      if (isMobile) {
        timeline.set(menuRef.current, { pointerEvents: open ? 'all' : 'none' })
        timeline.set(logoRef.current, { pointerEvents: open ? 'none' : 'all' })
        timeline.to(logoRef.current.children[0], { y: open ? '100%' : '0%', duration: 0.25, ease: 'sine.inOut' }, open ? 0 : 0.35)
        timeline.to(open ? reverse(toArray(links)) : links, { autoAlpha: open ? 1 : 0, y: open ? '0%' : '-50%', duration: 0.35, stagger: 0.075, ease: 'sine.inOut' }, open ? 0.15 : 0)
      } else {
        timeline.set(menuRef.current, { pointerEvents: 'all' })
        timeline.set(logoRef.current, { pointerEvents: 'all' })
        timeline.to(links, { autoAlpha: 1, y: '0%', duration: 0.35, stagger: 0.075, ease: 'sine.inOut' })
      }
      return () => {
        timeline.kill()
      }
    }
  }, [open, isMobile, loaded])

  const onLogoClick = useCallback((e) => {
    scrollToElement(document.getElementById('hero'))
    e.preventDefault()
  }, [])

  const onScrollToClick = useCallback((e, target) => {
    scrollToElement(document.getElementById(target))
    e.preventDefault()
  }, [])

  const onStillsClick = useCallback((e) => {
    scrollToElement(document.getElementById('featured'))
    setSelectedTab('still')
    e.preventDefault()
  }, [setSelectedTab])

  const onFeaturedClick = useCallback((e) => {
    scrollToElement(document.getElementById('featured'))
    setSelectedTab('moving')
    e.preventDefault()
  }, [setSelectedTab])

  return (
    <>
      <header className={classes.header} ref={headerRef}>
        <a ref={logoRef} href='#hero' onClick={onLogoClick} aria-label='Davros' className={classes.logoLink}><Logo className={classes.logo} title='Davros' /></a>
        <nav className={classes.nav} ref={menuRef}>
          <ul className={classes.list}>
            <li className={classes.item}><AnimatedLink className={classes.link} onClick={onFeaturedClick} href='#featured' text='Moving' /></li>
            <li className={classes.item}><AnimatedLink className={classes.link} onClick={onStillsClick} href='#featured' text='Still' /></li>
            <li className={classes.item}><AnimatedLink className={classes.link} onClick={(e) => onScrollToClick(e, 'information')} href='#information' text='Profile' /></li>
            <li className={classes.item}><AnimatedLink className={classes.link} onClick={(e) => onScrollToClick(e, 'all-projects')} href='#all-projects' text='Archived' /></li>
          </ul>
        </nav>
        <Hamburger />
      </header>
    </>
  )
}

const useStyles = createUseStyles({
  header: {
    margin: [theme.spacing(1), theme.spacing(1), 0],
    [theme.breakpoints.up('xs')]: {
      margin: [theme.spacing(2), theme.spacing(5), 0]
    },
    color: theme.colors.white,
    mixBlendMode: 'difference',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: theme.zIndex.header
  },
  nav: {
    position: 'absolute',
    left: 0,
    [theme.breakpoints.up('xs')]: {
      position: 'static',
      margin: [0, -12]
    }
  },
  list: {
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    margin: [0, theme.spacing(-0.5 / 2)],
    [theme.breakpoints.up('xs')]: {
      margin: [0, theme.spacing(-0.5)]
    },
    [theme.breakpoints.up('xs')]: {
      margin: [0, theme.spacing(-1)]
    }
  },
  item: {
    opacity: 0
  },
  link: {
    overflow: 'hidden',
    display: 'flex',
    minHeight: 42,
    alignItems: 'center',
    fontSize: 10,
    fontWeight: 'normal',
    textDecoration: 'none',
    margin: [0, theme.spacing(0.5)],
    textTransform: 'none',
    [theme.breakpoints.up('xxs')]: {
      margin: [0, theme.spacing(1)]
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: 14,
      margin: [0, theme.spacing(2)]
    }
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    alignSelf: 'center'
  },
  logo: {
    width: 122,
    height: 23,
    transform: 'translate(0, 100%)'
  }
}, { name: 'Layout' })

import React, { forwardRef } from 'react'

export default forwardRef(({ className }, ref) => {
  return (
    <svg className={className} viewBox='0 0 893 109' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 107.469V0H36.664C68.7559 0 90.898 18.422 90.898 53.7344C90.898 89.0467 69.518 107.469 37.4478 107.469H0ZM20.6398 89.0247H35.14C55.7798 89.0247 70.28 80.6015 70.28 53.7344C70.28 26.8672 55.7798 18.422 35.14 18.422H20.6398V89.0247Z' fill='white' />
      <path d='M102.829 86.4865C102.829 69.9024 115.653 62.4199 130.784 61.1509L150.031 59.4663V57.6285C150.102 55.9976 149.834 54.3698 149.246 52.8483C148.657 51.3269 147.76 49.9451 146.611 48.7908C145.463 47.6365 144.087 46.7349 142.573 46.1433C141.059 45.5517 139.44 45.2831 137.817 45.3545H136.576C129.108 45.3545 124.666 50.1021 124.209 54.5654H104.353C105.877 40.1254 118.091 29.2299 137.207 29.2299C156.323 29.2299 169.277 38.9003 169.277 56.8627V90.6436C169.168 96.2843 169.422 101.926 170.039 107.534H151.402C150.887 104.437 150.581 101.308 150.488 98.1699C147.882 101.699 144.462 104.539 140.521 106.445C136.58 108.351 132.237 109.265 127.867 109.109C114.129 109 102.829 100.248 102.829 86.4865ZM133.07 92.9408C135.283 92.9815 137.482 92.5787 139.538 91.7558C141.594 90.9329 143.466 89.7064 145.045 88.1482C146.624 86.5899 147.878 84.7311 148.734 82.6802C149.59 80.6293 150.031 78.4275 150.031 76.2035V74.0156L133.528 75.7003C128.498 76.1598 122.38 78.7633 122.38 85.0644C122.38 89.8339 126.495 92.897 131.851 92.897L133.07 92.9408Z' fill='white' />
      <path d='M238.534 30.6957H259.022L232.59 107.468H203.699L177.29 30.6957H198.365L218.526 92.7219L238.534 30.6957Z' fill='white' />
      <path d='M270.931 20.7193V0H292.311V20.7193H270.931ZM271.693 107.469V30.696H291.549V107.469H271.693Z' fill='white' />
      <path d='M306.985 68.9838C306.985 48.2646 318.742 29.077 340.906 29.077C351.596 29.077 359.847 35.2249 362.895 40.5852V0H382.752V107.469H362.895V97.4262C359.847 102.808 351.596 108.956 340.906 108.956C318.742 109 306.985 89.8124 306.985 68.9838ZM345.478 92.7879C354.644 92.7879 362.895 83.5769 362.895 68.9838C362.895 54.3907 354.644 45.2016 345.478 45.2016H344.259C334.331 45.2016 326.841 54.2594 326.841 68.8306C326.841 83.4019 334.331 92.7879 344.259 92.7879H345.478Z' fill='white' />
      <path d='M551.724 0H573.104L546.673 107.469H519.937L503.586 29.1644L487.105 107.469H460.369L434.09 0H455.317L474.411 85.1959L491.241 0H516.453L533.261 85.1959L551.724 0Z' fill='white' />
      <path d='M575.086 86.4865C575.086 69.9024 587.931 62.4199 603.062 61.1509L622.309 59.4663V57.6284C622.38 55.9957 622.112 54.3659 621.522 52.8429C620.932 51.3199 620.033 49.937 618.882 48.7824C617.731 47.6278 616.353 46.7267 614.837 46.1365C613.32 45.5463 611.698 45.28 610.073 45.3545H608.81C601.321 45.3545 596.901 50.1021 596.444 54.5654H576.588C578.112 40.1254 590.326 29.2299 609.42 29.2299C628.514 29.2299 641.468 38.8346 641.468 56.797V90.5779C641.359 96.2186 641.614 101.861 642.23 107.468H623.593C623.077 104.364 622.771 101.228 622.679 98.0823C620.075 101.613 616.654 104.454 612.713 106.36C608.772 108.266 604.429 109.179 600.058 109.022C586.407 109 575.086 100.248 575.086 86.4865ZM605.349 92.9408C607.561 92.9815 609.76 92.5787 611.816 91.7558C613.872 90.9329 615.744 89.7064 617.323 88.1481C618.902 86.5899 620.157 84.7311 621.013 82.6802C621.869 80.6293 622.309 78.4275 622.309 76.2035V74.0156L605.806 75.7003C600.755 76.1598 594.658 78.7633 594.658 85.0644C594.658 89.8339 598.773 92.897 604.129 92.897L605.349 92.9408Z' fill='white' />
      <path d='M661.411 107.469V0H681.267V107.469H661.411Z' fill='white' />
      <path d='M701.733 107.469V0H721.611V107.469H701.733Z' fill='white' />
      <path d='M741.315 20.7193V0H762.695V20.7193H741.315ZM742.077 107.469V30.696H761.933V107.469H742.077Z' fill='white' />
      <path d='M782.398 107.469V30.6962H802.254V41.8981C804.679 37.9536 808.079 34.7074 812.124 32.4759C816.168 30.2444 820.719 29.1036 825.332 29.1646C843.664 29.1646 851.916 41.4386 851.916 58.329V107.469H832.06V60.6263C832.06 50.6496 827.705 45.3112 819.976 45.3112H818.757C809.591 45.3112 802.254 54.0627 802.254 64.8052V107.491L782.398 107.469Z' fill='white' />
      <path d='M870.858 107.469V85.1958H893.022V107.469H870.858Z' fill='white' />
    </svg>
  )
})

import React, { useCallback, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import { useRecoilState } from 'recoil'
import { headerOpenState } from './headerState'
import gsap from 'gsap'

export default ({ children, props }) => {
  const classes = useStyles()
  const [open, setOpen] = useRecoilState(headerOpenState)
  const hamburgerRef = useRef()
  const onClick = useCallback(() => {
    setOpen(!open)
  }, [open, setOpen])
  useEffect(() => {
    const dot1 = hamburgerRef.current.children[0]
    const dot2 = hamburgerRef.current.children[1]
    const dot3 = hamburgerRef.current.children[2]
    gsap.to(dot1, { x: open ? 17 : 0, y: open ? -6 : 0, duration: 0.35, ease: 'sine.inOut' })
    gsap.to(dot2, { x: open ? 9 : 0, duration: 0.25, ease: 'sine.inOut' })
    gsap.to(dot3, { x: open ? -11 : 0, y: open ? 6 : 0, duration: 0.35, ease: 'sine.inOut' })
  }, [open])
  return (
    <button className={classes.hamburger} onClick={onClick} ref={hamburgerRef}>
      <div className={classes.dot1} />
      <div className={classes.dot2} />
      <div className={classes.dot3} />
    </button>
  )
}

const useStyles = createUseStyles({
  hamburger: {
    width: 34,
    height: 42,
    padding: 0,
    margin: 0,
    position: 'relative',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    borderRadius: '0',
    cursor: 'pointer',
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    }
  },
  dot: {
    position: 'absolute',
    backgroundColor: theme.colors.white,
    mixBlendMode: 'difference',
    width: 6,
    height: 6
  },
  dot1: {
    composes: ['$dot'],
    left: 0,
    top: 18
  },
  dot2: {
    composes: ['$dot'],
    left: 14,
    top: 18
  },
  dot3: {
    composes: ['$dot'],
    right: 0,
    top: 18
  }
}, { name: 'Layout' })

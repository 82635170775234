import { atom, selector } from 'recoil'
import keyBy from 'lodash/keyBy'
import filter from 'lodash/filter'
import { routeState } from './route'
import _get from 'lodash/get'

export const contentState = atom({
  key: 'content'
})

export const getProjects = selector({
  key: 'getProjects',
  get: ({ get }) => {
    return _get(get(contentState), ['page', 'projects'], [])
  }
})

export const getMovingProjects = selector({
  key: 'getMovingProjects',
  get: ({ get }) => {
    return filter(get(getProjects), p => p.projectType === 'moving')
  }
})

export const getProjectByPath = selector({
  key: 'getProjectByPath',
  get: ({ get }) => {
    const projects = get(getProjects)
    return keyBy(projects, p => `/${p.projectType}/${p.slug}`)
  }
})

export const getSelectedProjectByRoute = selector({
  key: 'getSelectedProjectByRoute',
  get: ({ get }) => {
    const projectsByPath = get(getProjectByPath)
    const pathname = get(routeState).pathname
    return projectsByPath[pathname]
  }
})

import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'
import Header from './Header'
import { RecoilRoot, useRecoilState, useRecoilValue } from 'recoil'
import MovingDialog from './MovingDialog'
import StillDialog from './StillDialog'
import keyBy from 'lodash/keyBy'
import get from 'lodash/get'
import { routeState } from '../state/route'
import { contentState, getProjectByPath } from '../state/content'
import { movingDialogOpenState, movingDialogProjectState } from './MovingDialog/movingDialogState'
import { stillDialogOpenState, stillDialogProjectState } from './StillDialog/stillDialogState'

const initializeState = (location, pageContext) => {
  return ({ set }) => {
    set(routeState, location)
    set(contentState, pageContext)
    const projectsByPath = keyBy(get(pageContext, ['page', 'projects'], []), p => `/${p.projectType}/${p.slug}`)
    const selectedProject = projectsByPath[location.pathname] || projectsByPath[location.hash.substring(1)]
    if (selectedProject) {
      if (selectedProject.projectType === 'moving') {
        set(movingDialogOpenState, true)
        set(movingDialogProjectState, selectedProject)
      } else {
        set(stillDialogOpenState, true)
        set(stillDialogProjectState, selectedProject)
      }
    }
  }
}

const Route = ({ children, location }) => {
  const projectsByPath = useRecoilValue(getProjectByPath)
  const [, setMovingDialogOpen] = useRecoilState(movingDialogOpenState)
  const [, setMovingDialogProject] = useRecoilState(movingDialogProjectState)
  const [, setStillDialogOpen] = useRecoilState(stillDialogOpenState)
  const [, setStillDialogProject] = useRecoilState(stillDialogProjectState)

  useEffect(() => {
    const popState = (e) => {
      const pathname = document.location.hash.substring(1)
      const selectedProject = projectsByPath[pathname]
      if (selectedProject) {
        if (selectedProject.projectType === 'moving') {
          setStillDialogOpen(false)
          setMovingDialogOpen(true)
          setMovingDialogProject(selectedProject)
        } else {
          setMovingDialogOpen(false)
          setStillDialogOpen(true)
          setStillDialogProject(selectedProject)
        }
      } else {
        setStillDialogOpen(false)
        setMovingDialogOpen(false)
      }
    }
    window.addEventListener('popstate', popState)
    return () => {
      window.removeEventListener('popstate', popState)
    }
  }, [projectsByPath]) // eslint-disable-line

  return (
    <>
      <Header />
      {children}
      <MovingDialog />
      <StillDialog />
    </>
  )
}

export default ({ children, location, pageContext }) => {
  const classes = useStyles() /* eslint-disable-line */
  return (
    <RecoilRoot initializeState={initializeState(location, pageContext)}>
      <Route location={location}>{children}</Route>
    </RecoilRoot>
  )
}

const useStyles = createUseStyles({
  '@global': {
    ...theme.global
  }
}, { name: 'Layout' })


import React from 'react'
import { createUseStyles } from 'react-jss'
import AnimatedLink from './AnimatedLink'

const CloseIcon = ({ className }) => {
  return (
    <svg className={className} viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.125' y='1.00653' width='1.38899' height='8.33395' transform='rotate(-45 0.125 1.00653)' fill='currentColor' />
      <rect x='6.01807' y='0.024353' width='1.38899' height='8.33395' transform='rotate(45 6.01807 0.024353)' fill='currentColor' />
    </svg>
  )
}

export default ({ onCloseClick }) => {
  const classes = useStyles()

  return (
    <AnimatedLink
      tag='button'
      className={classes.closeButton}
      onClick={onCloseClick}
      text={<span className={classes.text}>Close <CloseIcon className={classes.icon} /></span>}
    />
  )
}

const useStyles = createUseStyles({
  closeButton: {
    marginLeft: 'auto',
    fontSize: 10
  },
  text: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: 8,
    height: 8,
    marginLeft: 8,
    marginBottom: 1
  }
})

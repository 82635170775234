import React, { useRef, useCallback, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import gsap from 'gsap'
import { primaryInput } from 'detect-it'

const AnimatedLink = forwardRef(({ tag = 'a', className, link, text, onClick, ...rest }, ref) => {
  const classes = useStyles()
  const copyRef = useRef()
  const textRef = useRef()
  const Tag = tag

  const animate = () => {
    if (textRef.current) {
      gsap.set(textRef.current, { y: '100%', opacity: 0 })
      gsap.set(copyRef.current, { y: '0%', opacity: 1 })
      gsap.to(textRef.current, { y: '0%', opacity: 1, duration: 0.3, ease: 'sine.out' })
      gsap.to(copyRef.current, { y: '-100%', opacity: 0, duration: 0.3, ease: 'sine.out' })
    }
  }

  const onMouseEnter = useCallback(() => {
    animate()
  }, [])

  const handleClick = useCallback((e) => {
    if (primaryInput === 'touch') { animate() }
    if (onClick) { onClick(e) }
  }, [onClick])

  return (
    <Tag
      className={cn(classes.link, className)}
      onMouseEnter={primaryInput === 'touch' ? null : onMouseEnter}
      onClick={primaryInput !== 'touch' ? handleClick : null}
      onKeyDown={null}
      onMouseUp={primaryInput === 'touch' ? handleClick : null}
      ref={ref}
      aria-label={text}
      role='link'
      tabIndex={0}
      {...rest}
    >
      <div className={classes.inner}>
        <span className={classes.copy} ref={copyRef}>{text}</span>
        <span className={classes.text} ref={textRef}>{text}</span>
      </div>
    </Tag>
  )
})

const useStyles = createUseStyles({
  link: {
    padding: 0,
    fontSize: 12,
    backgroundColor: 'transparent',
    borderRadius: 0,
    border: 'none',
    outline: 'none',
    color: 'currentColor',
    textTransform: 'uppercase',
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'flex',
    margin: 0,
    position: 'relative',
    overflow: 'hidden'
  },
  inner: {
    overflow: 'hidden',
    display: 'block',
    position: 'relative'
  },
  text: {
    display: 'block',
    transform: 'translate(0, 0%)'
  },
  copy: {
    display: 'block',
    position: 'absolute',
    transform: 'translate(0, -100%)'
  }
})

export default AnimatedLink

import { useCallback, useRef } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const defaultOptions = {}

export default function useBodyScrollLock (locked, options = defaultOptions) {
  const state = useRef({ el: null, locked })

  // Note: useCallback() is better than useRef() for this because it will be notified if the element is recreated by
  // React without unmounting its component (e.g. this happens when an element is rendered into a portal when it was
  // previously rendered somewhere else).
  return useCallback((el) => {
    if (el && locked) {
      disableBodyScroll(el, { reserveScrollBarGap: true, ...options })
    }

    const { el: prevEl, locked: prevLocked } = state.current
    if (prevEl && prevLocked) {
      enableBodyScroll(prevEl)
    }

    state.current.el = el
    state.current.locked = locked
  }, [locked, options])
}

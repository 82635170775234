import React, { useCallback, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useRecoilState, useRecoilValue } from 'recoil'
import { movingDialogOpenState, movingDialogProjectState, movingProjectsForDialog } from './movingDialogState'
import gsap from 'gsap'
import theme from '../../style/theme'
import useDebouncedCallback from '../../hooks/useDebouncedCallback'
import useWindowResize from '../../hooks/useWindowResize'
import Video from '../Video'
import get from 'lodash/get'
import { isCurrentBreakpointMobile } from '../../state/layout'
import RichText from '../RichText'
import Dialog from '../Dialog'
import { primaryInput } from 'detect-it'
import TabButton from '../Slices/FeaturedProjects/TabButton'
import { navigateToProject } from '../../helpers/navigation'
import { getMovingProjects } from '../../state/content'

export default () => {
  const classes = useStyles()
  const [open, setOpen] = useRecoilState(movingDialogOpenState)
  const [project, setProject] = useRecoilState(movingDialogProjectState)
  const dialogRef = useRef()
  const playerRef = useRef()
  const contentRef = useRef()
  const isMobile = useRecoilValue(isCurrentBreakpointMobile)
  const playPromise = useRef()
  const projectsForDialog = useRecoilValue(movingProjectsForDialog)
  const allProjects = useRecoilValue(getMovingProjects)
  const projects = projectsForDialog || allProjects

  const onClose = useCallback(() => {
    window.history.pushState({}, null, '/')
    setOpen(false)
  }, [setOpen])

  const video = get(project, ['moving', 'fullVideo'])

  const resizeVideo = useCallback(() => {
    if (video) {
      const aspect = 1 / video.aspect
      const { width, height } = dialogRef.current.getBoundingClientRect()
      const innerHeight = height - (isMobile ? 100 : 400)
      const innerWidth = width - (isMobile ? (theme.margin * 2) : 256)
      let videoHeight = innerWidth * aspect
      let videoWidth = innerWidth

      if (videoHeight > innerHeight) {
        videoHeight = innerHeight
        videoWidth = innerHeight * (1 / aspect)
      }
      gsap.set(playerRef.current, { width: videoWidth, height: videoHeight })
      gsap.set(contentRef.current, { width: videoWidth })
    }
  }, [video, isMobile])

  useEffect(resizeVideo, [resizeVideo, video])
  useWindowResize(useDebouncedCallback(resizeVideo, 250, [resizeVideo]), false)

  useEffect(() => {
    if (playerRef.current && primaryInput !== 'touch') {
      if (open) {
        playPromise.current = playerRef.current.play()
      } else {
        if (playPromise.current) {
          playPromise.current.then(() => playerRef.current.pause())
        } else {
          playerRef.current.pause()
        }
      }
    }
  }, [open])

  const onPrevious = useCallback(() => {
    let index = projects.indexOf(project)
    index -= 1
    if (index < 0) index = projects.length - 1
    navigateToProject(projects[index])
    setProject(projects[index])
  }, [projects, project, setProject])
  const onNext = useCallback(() => {
    let index = projects.indexOf(project)
    index = (index + 1) % projects.length
    navigateToProject(projects[index])
    setProject(projects[index])
  }, [projects, project, setProject])

  return (
    <Dialog open={open} onClose={onClose} ref={dialogRef}>
      {project && (
        <div className={classes.project} ref={contentRef}>
          <Video vimeoVideo={video} ref={playerRef} />
          <div className={classes.title}>
            <h2>{project.title}</h2>
            <h3>{project.subtitle}</h3>
          </div>
          {project.details && <RichText className={classes.copy} content={project.details.text} />}
          <div className={classes.buttons}>
            <TabButton
              tabId='pref'
              text='Previous'
              position='left'
              selected={false}
              onClick={onPrevious}
              offset={0}
              animateHover
              className={classes.prevButton}
            />
            <TabButton
              tabId='next'
              text='Next'
              position='right'
              selected={false}
              onClick={onNext}
              offset={0}
              animateHover
              className={classes.nextButton}
            />
          </div>
        </div>
      )}
    </Dialog>
  )
}

const useStyles = createUseStyles({
  project: {
    margin: [theme.spacing(4), 'auto', 0],
    [theme.breakpoints.up('sm')]: {
      margin: [theme.spacing(8), 'auto', 0]
    }
  },
  title: {
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      marginTop: theme.spacing(2)
    },
    '& h2': {
      fontSize: 30,
      fontWeight: 'bold'
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 'normal',
      [theme.breakpoints.up('sm')]: {
        textAlign: 'right',
        fontSize: 30
      }
    }
  },
  copy: {
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '50%'
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  prevButton: {
    position: 'static',
    [theme.breakpoints.up('sm')]: {
      position: 'absolute'
    },
    '&.left': {
      transform: 'translate(0, 0)'
    }
  },
  nextButton: {
    position: 'static',
    [theme.breakpoints.up('sm')]: {
      position: 'absolute'
    },
    '&.right': {
      transform: 'translate(0, 0)'
    }
  }
})

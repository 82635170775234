import { atom, selector } from 'recoil'
import inDOM from 'dom-helpers/canUseDOM'
import { current, keys } from '../style/breakpoints'
import values from 'lodash/values'
import reduce from 'lodash/reduce'

export const siteLoadedState = atom({
  key: 'siteLoaded',
  default: {
    videoLoaded: false
  }
})

export const breakpointState = atom({
  key: 'breakpoint',
  default: current(),
  effects_UNSTABLE: [
    ({ node, setSelf }) => {
      const check = () => {
        const bp = current()
        const prev = node
        if (bp !== prev) {
          setSelf(bp)
        }
      }
      if (inDOM) {
        window.addEventListener('resize', check)
      }
      check()
      return () => {
        window.removeEventListener('resize', check)
      }
    }
  ]
})

export const isCurrentBreakpointMobile = selector({
  key: 'isCurrentBreakpointMobile',
  get: ({ get }) => {
    const breakpoint = get(breakpointState)
    return !(keys.indexOf(breakpoint) >= keys.indexOf('xs'))
  }
})

export const isCurrentBreakpointSmall = selector({
  key: 'isCurrentBreakpointSmall',
  get: ({ get }) => {
    const breakpoint = get(breakpointState)
    return !(keys.indexOf(breakpoint) >= keys.indexOf('sm'))
  }
})

export const isSiteLoaded = selector({
  key: 'isSiteLoaded',
  get: ({ get }) => {
    const loaded = get(siteLoadedState)
    return reduce(values(loaded), (result, value) => { return result && value }, true)
  }
})

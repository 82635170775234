import { atom } from 'recoil'

export const movingDialogOpenState = atom({
  key: 'movingDialogOpen',
  default: false
})

export const movingDialogProjectState = atom({
  key: 'movingDialogProject',
  default: null
})

export const movingProjectsForDialog = atom({
  key: 'movingProjectsForDialog',
  default: []
})

import React, { useRef, useCallback, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'
import cn from 'classnames'
import gsap from 'gsap'
import { primaryInput } from 'detect-it'
import { useRecoilValue } from 'recoil'
import { isCurrentBreakpointSmall } from '../../../state/layout'

export default ({ className, tabId, text, position = 'left', onClick, onMouseEnter, onMouseLeave, selected, offset = 100, animateHover }) => {
  const classes = useStyles({ position })
  const textRef = useRef()
  const mobile = useRecoilValue(isCurrentBreakpointSmall)

  const handleMouseEnter = useCallback(() => {
    if (onMouseEnter) onMouseEnter(tabId)
    if (!selected && !mobile) {
      gsap.to(textRef.current, { ease: 'expo.out', duration: 1, x: position === 'left' ? offset : -offset })
    }
  }, [onMouseEnter, position, offset, selected, tabId, mobile])

  const handleMouseLeave = useCallback(() => {
    if (onMouseLeave) onMouseLeave(tabId)
    if (!selected && !mobile) {
      gsap.to(textRef.current, { ease: 'expo.out', duration: 1, x: 0 })
    }
  }, [onMouseLeave, selected, tabId, mobile])

  const handleOnClick = useCallback(() => {
    if (onClick) onClick(tabId)
  }, [onClick, tabId])

  useEffect(() => {
    gsap.to(textRef.current, { ease: 'expo.out', duration: 1, x: 0 })
  }, [position, selected, offset])

  return (
    <button
      className={cn(className, classes.panel, position, { selected })}
      onMouseEnter={primaryInput !== 'touch' ? handleMouseEnter : null}
      onMouseLeave={primaryInput !== 'touch' ? handleMouseLeave : null}
      onClick={handleOnClick}
    >
      <span className={cn(classes.text, position)} ref={textRef}>
        {text}
      </span>
    </button>
  )
}

const useStyles = createUseStyles({
  panel: {
    zIndex: 3,
    position: 'absolute',
    width: theme.spacing(12),
    pointer: 'cursor',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    outline: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    top: 0,
    bottom: 0,
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 'normal',
    cursor: 'pointer',
    '&.selected': {
      pointerEvents: 'none'
    },
    color: theme.colors.white,
    mixBlendMode: 'difference',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('xs')]: {
      fontSize: 12
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0
    },
    '&.left': {
      left: '50%',
      transform: `translate(calc(-100% + ${theme.spacing(1)}), 0)`,
      bottom: 0,
      top: 'auto',
      justifyContent: 'flex-start',
      width: 'auto',
      [theme.breakpoints.up('sm')]: {
        transform: 'translate(0, 0)',
        width: theme.spacing(12),
        left: 0,
        top: 0
      }
    },
    '&.right': {
      right: '50%',
      transform: `translate(calc(100% + ${theme.spacing(1)}), 0)`,
      justifyContent: 'flex-end',
      bottom: 0,
      top: 'auto',
      width: 'auto',
      [theme.breakpoints.up('sm')]: {
        transform: 'translate(0, 0)',
        right: 0,
        width: theme.spacing(12),
        top: 0
      }

    }
  },
  text: {
    height: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: [0, theme.spacing(1)],
    margin: [0, theme.spacing(1)],
    position: 'relative',
    '&.left': {
      transform: 'rotate(0deg)',
      [theme.breakpoints.up('sm')]: {
        transform: 'rotate(90deg)',
        margin: [0, theme.spacing(1)]
      }
    },
    '&.right': {
      transform: 'rotate(0deg)',
      [theme.breakpoints.up('sm')]: {
        transform: 'rotate(-90deg)',
        margin: [0, theme.spacing(1)]
      }
    }
  },
  border: {
    fontSize: 8,
    transform: 'scale(0)',
    opacity: 0
  }
})

const React = require('react')
const Layout = require('./src/components/Layout').default
const initLazySizes = require('./src/initLazySizes')
const gsap = require('gsap').default
const Flip = require('gsap/Flip').default

gsap.registerPlugin(Flip)

initLazySizes.default()

exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}

import { atom } from 'recoil'

export const stillDialogOpenState = atom({
  key: 'stillDialogOpen',
  default: false
})

export const stillDialogProjectState = atom({
  key: 'stillDialogProject',
  default: null
})

export const stillDialogSelectedImageState = atom({
  key: 'stillDialogSelectedImage',
  default: null
})

import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'

const ContactDetails = ({ node: { email, phone, representation, title } }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <h3 className={classes.title}>{title}</h3>
      <div className={classes.contactContainer}>
        <div className={classes.links}>
          {email.url && <a href={email.url} className={classes.link}>{email.text}</a>}
          {phone.url && <a href={phone.url} className={classes.link}>{phone.text}</a>}
        </div>
        <div>
          {representation}
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    marginTop: '4em'
  },
  contactContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  title: {
    marginBottom: '2em'
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '1em',
    marginBottom: '1em',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      minWidth: 300
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: theme.vw(300, 'lg')
    }
  },
  link: {
    display: 'inline-block',
    marginBottom: theme.spacing(0.5)
  }
}, { name: 'ContactDetails' })

export default ContactDetails

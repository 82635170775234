import React, { useMemo, forwardRef, useCallback, useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import BlockContent from '@sanity/block-content-to-react'
import map from 'lodash/map'
import merge from 'lodash/merge'
import ResponsiveImage from './ResponsiveImage'
import theme from '../style/theme'
import gsap from 'gsap'
import ContactDetails from './ContactDetails'

const serializers = {
  marks: {
    hover_image: (props) => {
      const classes = useStyles() // eslint-disable-line
      const { mark: { image } } = props
      const imageRef = useRef() // eslint-disable-line
      const localsRef = useRef() // eslint-disable-line
      const hoverImageBackgroundRef = useRef() // eslint-disable-line
      const mouseOver = useCallback(() => { // eslint-disable-line
        if (localsRef.current) {
          clearTimeout(localsRef.current)
        }
        const parentElement = imageRef.current.parentNode
        const { left, width } = parentElement.getBoundingClientRect()
        const centerPosition = left + (width / 2)
        const elementWidth = imageRef.current.offsetWidth
        const windowWidth = window.innerWidth
        const rightOffset = centerPosition + (elementWidth / 2)
        const leftOffset = centerPosition - (elementWidth / 2)
        if (rightOffset > (windowWidth - 16)) {
          const offset = rightOffset - (windowWidth - 16)
          imageRef.current.style.left = `calc(50% - ${offset}px)`
          imageRef.current.style.transformOrigin = '100% 50%'
        } else if (leftOffset <= 16) {
          const offset = -leftOffset + 16
          imageRef.current.style.left = `calc(50% + ${offset}px)`
          imageRef.current.style.transformOrigin = '0% 50%'
        } else {
          imageRef.current.style.left = '50%'
          imageRef.current.style.transformOrigin = '50% 50%'
        }
        gsap.to(imageRef.current, { scale: 1, duration: 1.25, ease: 'elastic.out(1, 0.3)' })
        gsap.to(imageRef.current, { opacity: 1, duration: 0.25, ease: 'sine.out' })
      }, [])
      const onBlur = useCallback(() => { // eslint-disable-line
        gsap.to(imageRef.current, { scale: 0.9, opacity: 0, duration: 0.25, ease: 'sine.out' })
      }, [])
      const mouseLeave = useCallback(() => { // eslint-disable-line
        localsRef.current = setTimeout(onBlur, 500)
      }, [onBlur])

      useEffect(() => {  // eslint-disable-line
        const timeline = gsap.timeline({ repeat: -1, yoyo: 1 })
        timeline.to(hoverImageBackgroundRef.current, { opacity: 0.2, duration: 1.5 })
        return () => {
          timeline.kill()
        }
      }, [])

      const aspect = image.aspect > 1 ? 'landscape' : 'portrait'

      return (
        <span
          className={classes.hoverImage}
          onMouseOver={mouseOver}
          onMouseLeave={mouseLeave}
          onFocus={mouseOver}
          onBlur={onBlur}
          role='button'
          aria-label='Hover Image'
          tabIndex={-1}
        >
          <ResponsiveImage ref={imageRef} {...image} tag='span' className={cn(classes.image, aspect)} />
          <span className={classes.hoverImageBackground} ref={hoverImageBackgroundRef} />
        </span>
      )
    }
  },
  types: {
    contact: ContactDetails
  }
}

const RichText = forwardRef(({ className, content, tag = 'div', serializers: extraSerializers, children }, ref) => {
  const classes = useStyles()
  const Component = tag
  const allSerializers = useMemo(() => {
    if (extraSerializers) {
      return merge({}, serializers, extraSerializers)
    }
    return serializers
  }, [extraSerializers])
  const blocks = useMemo(() => map(content, block => ({
    _type: block.type, // BlockContent expects `_type` prop, not `type`.
    ...block
  })), [content])
  return (
    <Component className={cn(className, classes.container)} ref={ref}>
      <BlockContent blocks={blocks} serializers={allSerializers} />
      {children}
    </Component>
  )
})

const useStyles = createUseStyles({
  container: {
    '& > div > *:last-child': {
      marginBottom: 0
    }
  },
  hoverImage: {
    cursor: 'pointer',
    display: 'inline-block',
    width: '1em',
    height: '1.4em',
    position: 'relative',
    outline: 'none'
  },
  hoverImageBackground: {
    position: 'absolute',
    display: 'block',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.black
  },
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0.9)',
    opacity: 0,
    transformOrigin: '50% 50%',
    pointerEvents: 'none',
    '&.landscape': {
      width: 300,
      [theme.breakpoints.up('lg')]: {
        width: theme.vw(300, 'lg')
      }
    },
    '&.portrait': {
      width: 200,
      [theme.breakpoints.up('lg')]: {
        width: theme.vw(200, 'lg')
      }
    }
  }
}, { name: 'RichText' })

export default RichText

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-better-jss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Davros","short_name":"Davros","start_url":"/","icon":"src/images/fav-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7e616e5a742eabaaea61ca02b9b1e7ac"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
